@import 'variables';


$attachment-bg-color: #D2D2D2;
$card-border: rgb(220, 220, 220);
$card-height-desktop: 96px;
$card-height-mobile: 80px;

.activity-cards-container{
    display: flex;
    justify-content: space-between;
    gap: 12px 12px;
    padding: 0;
    padding-bottom: 1rem;
    @media (max-width: 767px) {
      flex-wrap: nowrap;
      gap: 10px 10px;
      // hide scrollbars
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    &.status-card-container {
      gap: 12px 13px;
    }
}
.activity-cards {
    margin-top: 10px;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(500px ,1fr))
    }
  }


.activity-card {
  height: $card-height-desktop;
  border: 1px solid $card-border;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 10px;
  width: 200px;
  cursor: pointer;
  &.unselected{
    opacity: 0.5;
  }
  .icon-circle{
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    i{
      min-width: 21px !important
    }
    @include inProcess;
    @include classification;
    @include needsAttention;
    @include paused;
    @include rejected;
    @include finalReview;
    @include autoProcess;
    @include approved;
    @include completed;
    @include failed;
    @include completedToday;
    @include replied;
    @include factored;
    @include error;
  }
  .card-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    span{
      color: rgb(189, 189, 189)
    }
    > p {
      margin: 0;
      @include inProcess;
      @include classification;
      @include needsAttention;
      @include paused;
      @include rejected;
      @include finalReview;
      @include autoProcess;
      @include approved;
      @include completed;
      @include failed;
      @include completedToday;
      @include replied;
      @include error;
      @include factored;
    }
  }
  @media(max-width: 767px) {
    height: 80px;
    min-width: 200px;
    .icon-circle{
      width: 64px;
      height: 64px;
    }
  }
  &.status-card {
    height: 80px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    .card-info {
      font-size: 22px;
    }
    .card-title {
      flex-basis: 100%;
      text-align: center;
      margin-top: 0px;
      font-size: 13px;
    }
    .icon-circle {
      width: 30px;
      height: 30px;
      i {
        font-size: 13px;
      }
    }
  }
}

.card-divider{
  position: absolute;
  height: 100%;
  width: 12px;
  background-color: white;
}
.cards-loader{
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #2e86df;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  width: 836px;
  position: relative;
  margin-bottom: 1rem;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}
