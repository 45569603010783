@import 'variables';

$event-bg: rgb(248, 248, 248);
$event-border: rgb(188, 188, 188);
$event-body: rgb(222, 222, 223);

$success-bg: rgb(195,233, 224);
$success-text: rgb(6, 130, 100);
$success-body: rgb(50, 179, 148);
$success-body-text: white;

$warning-bg: rgb(255, 211, 182);
$warning-text: rgb(233, 92, 0);
$warning-body: rgb(242, 113, 28);
$warning-border: rgb(246, 163, 108);

$error-bg: rgb(255, 213, 213);
$error-border: rgb(224, 180, 180);
$error-text: rgb(145, 45, 43);
$error-body-bg: rgb(189,70, 68);

.daily-events-container{
  display: flex;
  flex-direction: column-reverse;
  &.wide{
    margin-left: 72px;
  }
}

.smp-event-container {
  display: flex;
  justify-content: flex-start;
  gap: 0 18px;
  margin-top: 12px;
  position: relative;
  &.wide{
    margin-left: 72px;
  }
  &:first-child{
    .vbar{
      display: none;
    }
  }
  &:only-child{
    .vbar{
      display: block;
      height: 20px;
    }
  }
}

.vbar{
  border-left: 1px solid gray;
  position: absolute;
  top: -12px;
  left: 18px; //half of the avatar size
  height: calc(100% + 32px);
}

.smp-event-avatar {
  background-color: white !important;
  margin-top: 8px;
  width: $avatar-size !important;
  height: $avatar-size;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1;
  min-width: $avatar-size;
}

.smp-event-date-container {
  color: rgb(96, 96, 96);
  padding-top: 12px;
  padding-bottom: 2px;
  border-bottom: 1px solid rgb(128, 128, 128);
}
.smp-event {
  background-color: $event-bg;
  border: 1px solid $event-border;
  border-radius: 8px;
  padding: 12px;
  color: black;
  flex: 1 1 0px;

  a {
    color: #555;
    opacity: 1;
    &:hover {
      color: initial;
    }
  }

  h5 {
    margin-bottom: 0
  }

  .header {
    display: flex;
    justify-content: space-between;
    gap: 0 6px;
  }
  .message {
    background: $event-body;
    padding: 12px;
    border-radius: 8px;
    margin-top: 8px;
  }

  &.success {
    background-color: $success-bg;
    border-color: $success-body;
    color: $success-text;
    .message{
      background: $success-body;
      color: $success-body-text;
    }
  }

  &.warning{
    background-color: $warning-bg;
    border-color: $warning-border;
    color: $warning-text;
    .message {
      background-color: $warning-body;
      color: $success-body-text
    }
  }

  &.error {
    background-color: $error-bg;
    border-color: $error-text;
    color: $error-text !important;
    .message {
      background-color: $error-body-bg;
      color: white;
      text-align: left !important;
    }
  }
}

.add-file-button {
  padding: 8px 0 0 2px !important;
  background: 0 !important;
}

.delete-event-icon {
  color: #555;
  cursor: pointer;
}
