.formWrapper {
  background: #ffffff;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  .fieldsWrapper {
    display: flex;
    flex-direction: column;
    .fieldWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .fieldTitle {
        width: 10%;
      }
      .fieldInput {
        width: 90%;
      }
    }
  }
  .filesWrapper {
    margin-bottom: 8px;
    .filesList {
      display: flex;
      flex-wrap: wrap;
      max-height: 100px;
      gap: 8px;
      overflow-x: auto;
      padding: 10px;
      margin-top: 8px;
    }
  }
  .firstField {
    margin-bottom: 8px !important;
  }
  .lastField {
    margin-bottom: 24px !important;
  }
  .btsWrapper {
    display: flex;
    justify-content: space-between;
    .button {
      background-color: #227d98 !important;
      color: #ffffff !important;
      &:hover {
        background-color: #19596c !important;
      }
    }
    .left {
      display: flex;
      align-items: center;
    }
  }
}
.textArea {
  width: 100%;
  padding: 12px;
  height: 250px !important;
  resize: none;
  border-radius: 4px;
  padding-bottom: 50px;
}

.closeIcon {
  font-size: 10px;
  color: black;
  cursor: pointer;
  &:active {
    transform: scale(0.8) !important;
  }
}

.error {
  color: red;
}
