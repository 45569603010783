.fieldWrapper {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.fieldBlock {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.checkboxes {
  display: flex;
  gap: 50px;
  padding: 0 30px;
}
