.contentWrapper {
  height: 100%;
  background-color: #fff;
  border: 0.5px solid #DEDEDF;
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 15px 15px 0;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DEDEDF;
  padding-bottom: 13px;
  padding-left: 30px;
  padding-right: 30px;
}
.batchId {
  font-family: 'LatoRegular', serif;
  font-size: 12px;
  line-height: 14px;
  color: #808080;
}
.backButton {
  background-color: #227D98 !important;
  color: #fff !important;
}
.clientName {
  font-family: 'LatoRegular', serif;
  font-size: 14px;
  line-height: 17px;
  color: #0E0E2C;
  font-weight: 700;
}
.clientEmail {
  font-family: 'LatoRegular', serif;
  font-size: 12px;
  line-height: 14px;
  color: #606060;
  margin-top: 5px;
}
.batchInfoWrapper {
  display: flex;
  justify-content: space-between;
  padding: 26px 15% 26px 30px;
  align-items: center;
  border-bottom: 1px solid #DEDEDF;
}
.innerContent {
  display: flex;
}
.pdfContent {
  width: 100%;
  margin-top: 8px;
  margin-left: 25px;
}
.topButton {
  color: #227D98 !important;
  background-color: rgba(34, 125, 152, 0.3) !important;
}
.topButtonWrapper {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}
