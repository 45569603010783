.contentWrapper {
  padding: 24px 24px 12px;
  background-color: #fff;
  border: 0.5px solid #DEDEDF;
  height: 100%;
  display: flex;
  flex-direction: column;
}


