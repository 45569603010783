.panel {
  background: rgba(34, 125, 152, 0.3);
  border-radius: 4px;
  display: flex;
  padding: 13px 16px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding-right: 58px;
  &.paused {
    background: #A3BFC2;
  }
}
.panelText {
  color: #000000;
  font-family: 'LatoRegular';
}
.panelDate {
  color: #606060;
  font-size: 12px;
  position: relative;
  bottom: -1px;
}
.panelIcon {
  position: absolute;
  right: 16px;
  top: 11px;
}
.emailsList {
  padding-left: 24px;
  padding-right: 24px;
}
