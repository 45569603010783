.configSection {
  margin-top: 15px;
  background: white;
  padding: 10px;
  width: 33%;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);

  & > .title {
    text-align: center;
    font-size: 18px;
    color: black;
    margin-bottom: 10px;
  }

  .field {
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 3px;
    margin-bottom: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      color: grey;
      width: 50%;
      margin-right: 8px;

      .tooltip {
        cursor: pointer;
        padding-right: 30px;
      }
    }

    .input {
      width: 50%;
    }
  }
}

.wrapper {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}
