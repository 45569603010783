.noRules {
  text-align: center;
  margin-top: 30px;
  font-family: 'LatoRegular', serif;
  font-size: 14px;
  color: #0E0E2C;
}

.rulesActionsContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  margin-bottom: 16px;
  gap: 10px;
  .action {

  }

}
