.card {
  background-color: rgba(54, 157, 187, 0.15);
  border-radius: 2px;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 16px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #227D98;
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 600 !important;
      font-size: 14px;
      & span {
        font-weight: 600 !important;
      }
      & > div  {
        padding-left: 0;
      }
    }
    .actions {
      display: flex;
      gap: 8px;

      .downloadIcon {
        color:#227D98;
      }
    }
  }
  .pagesWrapper {
    display: flex;
    margin-top: 16px;
    gap: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 2px;
  }
}
.headerCaret {
  color: rgb(34, 125, 152);
}
