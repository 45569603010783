@import 'variables';

.admin-section {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.admin-card {
  border-radius: 3px;
  background: white;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 2px gray-border(0.25);
  .title {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    color: black;
    margin-bottom: 8px;
  }
  .icon {
    color: rgb(96, 96, 96);
    margin-bottom: 8px;
  }
  .subtitle {
    color: rgb(96, 96, 96);
  }
  &:hover {
    transform: scale(0.98);
  }
  &:active{
    transform: scale(0.95);
  }
}

.admin-table-container {
  padding: 10px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  padding: 32px;
}

/* Form card styling */
.users-form {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  // max-width: 800px;
}

/* Inline field styling */
.inline-field {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.inline-field label {
  width: 120px;
  font-weight: bold;
}

// .inline-field .text-input {
//   flex: 1;
// }

.ui.input input[type="text"],
.ui.input input[type="email"],
.ui.input input[type="password"] {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Select box styling */
.ant-select {
  width: 100%;
}

.ant-select-selector {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Submit button styling */
.submit-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.submit-button a,
.submit-button button {
  padding: 12px 24px;
  border-radius: 4px;
  margin-left: 16px;
}

.submit-button a {
  background-color: #e0e0e0;
  color: #333;
  text-decoration: none;
}

.submit-button button {
  background-color: #006d8d;
  color: white;
  border: none;
}

.submit-button a:hover,
.submit-button button:hover {
  opacity: 0.9;
}