.wrapper {
  padding: 12px 8px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;

    color: #227d98;
    .headerRight {
      display: flex;
      .closeIcon {
        display: flex;
        color: black;
        cursor: pointer;
        &:active {
          transform: scale(0.8) !important;
        }
      }
      .templateSelector {
        margin-right: 20px;
        width: 250px;
      }
    }

  }
  .content {
    margin-top: 24px;
    height: 681px;

    background: #f6f8fa;
    border-radius: 4px;
    padding: 16px;
    &Scrolable {
      overflow-x: auto;
    }
    .emailContent {
      height: 100%;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 4px;
      padding: 24px;
      align-items: flex-end;
      gap: 10px;
      .emailsWrapper {
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }
      .newMailButton {
        width: 113px;
        background-color: #227d98 !important;
        color: #ffffff !important;
        &:hover {
          background-color: #19596c !important;
        }
      }
    }
  }
}

.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
