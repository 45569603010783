/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS (and SCSS, if configured) file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require aria_core/App
 *= require_tree .
 *= require_self
 */
 .login-alert {
    text-align: center;
    padding: 10px;
    background-color: #f44336;
    color: white;
    font-size: larger;
    font-weight: bolder;
  }

  .login-success {
    text-align: center;
    padding: 10px;
    background-color: #4BB543;
    color: white;
    font-size: larger;
    font-weight: bolder;
  } 