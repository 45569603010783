$success-bg: rgb(50, 179, 148);
$locked-bg: rgb(248, 172, 88);
$exception-bg: rgb(251, 174, 139);
$fail-bg: rgb(237, 85, 101);
$default-bg: rgb(234, 240, 245);
$default-color: rgb(28, 39, 53);

.item-status {
  padding: 3px 6px;
  border-radius: 6px;
  background: $default-bg;
  color: $default-color;
  word-break: keep-all;
  &.success {
    background: $success-bg;
    color: white;
  }
  &.locked-item{
    background: $locked-bg;
    color: white;
  }
  &.pending {
    background: $exception-bg;
    color: white;
  }
  &.fail {
    background: $fail-bg;
    color: white;
  }
}