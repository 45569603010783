@import 'variables';

.buttons-container {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //background: #f2f2f2;
  margin-bottom: 12px;
  border-radius: 4px;
  gap: 6px;
  &.loader{
    height: 53px;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #2e86df;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    margin-bottom: 1rem;
  }
  &.workItemTable {
    background-color: transparent;
  }
}

.desktop-table-container{
  width: 100%;
  overflow-x: auto;
  padding: 0.5em;
  min-height: 300px;
  margin-bottom: 4px;
  color: #909090;

  &.empty {
    overflow: hidden;
    min-height: 43px !important;
  }

  tr.resizing {
    > th:not(.select-all) {
      cursor: ew-resize !important;
    }
  }
  .select-all {
    display: flex;
    gap: 8px;
  }

  table {
    display: grid;
  }

  table thead,
  table tbody,
  table tr {
    display: contents;
    &.clickable-row{
      cursor: pointer;
    }
  }
  &.adminTable {
    height: calc(-130px + 100vh);
  }
  &.workItemTable {
    font-size: 11px;
    height: calc(-241px + 100vh);
    .ui.table thead th {
      border-bottom: none;
    }
    .selected-row {
      &:hover {
        > td {
          background: rgba(0,0,0,.03) !important;
        }
      }
      td {
        background-color: transparent  !important;
        color: #909090;
        border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
      }
    }
  }
}

.table-header-cell {
  position: relative;
  padding: 12.6px 14px !important;
}

.filter-cell {
  font-weight: 400 !important
}

.resize-handle {
  display: block;
  cursor: ew-resize;
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 70%;
  box-sizing: border-box;
  border-right: 1px solid gray-border();
  margin: 5px 0;

  &:hover{
    border-right: 2px solid #ccc;
  }

  &.active{
    border-right: 2px solid #517ea5;
  }
}

.pagination-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-page-info{
  color: #808080
}

.per-page-selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.per-page-label {
  color: #606060;
  margin-right: 0.5em
}

.table-button {
  padding: 0.7em 1em !important;
}

.table-loader {
  align-self: center;
}

.table-empty {
  font-size: 20px;
}

table tr.clickable-row:hover > td{
  background: rgba(0, 0 ,0 , 0.03) !important;
}

table tr > td{
  align-items: center;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

// .loading-table{
//   width: 100%;
//   .loading-cell{
//     height: 42px;
//     border-right: 1px solid rgba(34,36,38,0.15);
//     padding: 0px 5px;
//     .loader-template{
//       height: 24px;
//       width: 120px;
//       background-color: #eeeeee;
//     }
//   }
// }
.loading-table{
  padding: 0.5em;
}
.loading-cell{
  height: 42px;
  position: relative !important;
}
.head-divider{
  position: absolute;
  width: 1px;
  height: 70%;
  border-right: 1px solid rgba(34,36,38,0.15);
  top: 0;
  margin-top: 5px;
  right: 0;
}
.loader-template{
  height: 24px;
  width: 120px;
  background-color: #eeeeee;
}
.loader-table-body td{
  display: table-cell !important;
  padding: 12.6px 14px !important;
}
.loader-table-body td:first-child{
  padding-left: 0px !important;
}

.forbidden-click{
  cursor: not-allowed !important;
}

.filterTags {
  &.ui.menu .active.item {
    background: #227D98;
    color: #fff;
    // &:hover {
    //   background: #227D98;
    // }
  }
  &.ui.menu {
    border: none;
    box-shadow: none;
    font-size: 12px;
    min-height: 32px;
    margin-top: 15px;
    margin-bottom: 16px;
    .item {
      color: #808080;
      border-radius: 4px;
      padding: 0px 20px;
      line-height: 12px;
      &::before {
        content: none !important;
      }
    }
  }
}

.expanded-row {
  width: 100%;
  min-height: 300px;
  height: 100%;
  padding-top: 42px;
  text-align: center;
}

.selected-row {
  td {
    background-color: #227b9675  !important;
    color: white;
    border-top: 2px solid rgba(34, 36, 38, 0.1) !important;
  }
}


.select-all-pages{
  align-items: center;
  background-color: #eaf2ff;
  border-radius: 4px;
  box-sizing: border-box;
  color: #1d1d1d;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4px 0;
  min-height: 48px;
  padding: 6px 16px;
  gap: 4px;
}
