.cardWrapper {
  gap:8px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}

.chartsWrapper {
  padding-bottom: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 28px;
  width: 100%;
  .chartBlock {
    width: 48%;
  }

}

.chartWrapper {
  height: 515px;
}

.title {
  padding-left: 10px;
  color: #123B60;
  font-family: Lato, serif;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
}
