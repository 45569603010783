.header-actions{
    height: 80px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    align-items: center;
    padding: 0 24px;
    gap: 0 8px;
    &.with-totals {
        justify-content: space-between;
    }
}

.gutter {
    background-color: #eee;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-vertical {
    cursor: row-resize;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
}

.gutter.gutter-horizontal {
    cursor: col-resize;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}

.invoice-table-container{
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    .desktop-table-container{
        min-height: 0 !important;
        padding: 0 0.5em;
        margin-bottom: 0;
        height: 100%;
        .expanded-row {
            height: initial;
        }
        thead th {
            position: sticky;
            top: 0;
            z-index: 1;
            background: white !important;
        }
        tr td.fixed-edit-row{
            position: sticky;
            top: 43px;
            z-index: 1;
            background: white !important;

        }
    }
}


.header-actions, .invoice-table-edit{
    input:disabled{
        background-color: #f1f1f1 !important;
    }
    .react-select-custom{
        width: 100%;
        // min-width: 175px;
    }
}
.email_buttons_container{
    display: flex;
    .attach-button{    
        cursor: pointer;
        border: 0.5px solid #bcbdbd;
        background: #3b3b3b;
        width: fit-content;
        color: #bcbdbd;
        padding: 8px;
        margin-bottom: 16px;
        margin-right: 8px;
        position: relative;
        &:hover{
            background: #676767;
        }
    }
}

button#back_to_email{
    background-color: #1b1c1d !important;
    &:hover{
        background-color: #666667 !important;    }
    i{
        margin-right: 8px !important;
    }
}
.email-list-toolbar{
    height: 48px;
    background-color: #606060;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button{
      color: #f9f9fa !important;
      background: none !important;
      &:hover{
        background-color: #666667 !important;
      }
    }
}

.email-list-item{
    padding: 16px;
    cursor: pointer;
    border-bottom: 0.5px solid #00000026;
    display: flex;
    width: 100%;
    .email-body {
        width: 75%;
        > * {
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p {
            margin-bottom: 8px;
        }
        .email-title {
            font-size: 20px;
        }
        .subject-text{
            color: #75C9E2;
            font-size: 12px;
        }
    }
    .email-metadata {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 8px;
        width: 25%;
        i {
            font-size: 20px;
        }
        .email-timestamp {
            > time {
                display: block;
                text-align: right;
                font-size: 12px;
            }
        }
    }
    &:hover{
        background-color: rgb(230, 242, 250) !important;
    }
    &.active{
      background-color: rgb(205, 230, 247) !important;
      .email-body {
        .email-title {
            color: rgb(31, 31, 34);
        }
        .subject-text {
            color: #505050;
        }
      }
      .email-metadata {
            color: #505050
        }
    }
}
#email_body_container{
    height: 100%;
}
.email-container{
    background: rgb(31, 31, 34);
    padding: 24px;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
}
.email-info-name{    
    font-size: 24px;
    font-weight: 600;
    color: white;
    margin-bottom: 8px;
    line-height: 1.2;
}
.email-info{
    margin-bottom: 16px;
    .initials{
        margin-right: 8px;
        font-size: 18px;
        background: #4EC8D6;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }
}

.line-skeleton{
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #2e86df;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 16px;
    // width: 836px;
    position: relative;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }

  .email-list-item-skeleton{
    padding: 16px;
    border-bottom: 0.5px solid #00000026;
}
.loader-button-container{
    position: absolute;
    width: 100%;
    height: 46px;
    background-color: #ffffff80;
    margin: -8px;
    align-items: center;
    display: flex;
}

.update-status-container {
    display: flex;
    gap: 0 8px;
}

.cash-app-events {
    min-width: 250px;
    padding: 12px;
    overflow: auto;
    h2 {
        color: black;
        margin-bottom: 0;
    }
}

.checks-container{
    height: 100%;
    flex-wrap: wrap;
    position: relative;
}

.main-checks-container{
    width: 70%;
}

@media only screen and (max-width: 1440px){
    .checks-container{
        .main-checks-container{
            width: 100% !important;
        }
        .collapsable-container{
            width: 100% !important;
            .collapsable-panel-handler{
                display: none;
            }
            .content{
                border-top: 1px solid rgba(0,0,0,0.15);
                display: block !important;
                height: 100%;
                overflow: auto;
                width: 100%;
                padding-top: 0 !important;
            }
        }
    }
}

.is-modified {
    display: none;
}

.inv-table-loader {
    padding-right: 8px;
}

.empty-emails {
    span {
        font-size: 14px !important;
    }
}

.not-assigned-message {
    position: fixed !important;
    bottom: 14px;
    z-index: 1;
    .body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .dismiss {
        color: #606060;
        cursor: pointer;
    }
}
.thin{
    .not-assigned-message{
        width: calc(100% - 220px - 28px);
        left: 220px + 14px;
    }
}
.very.thin{
    .not-assigned-message{
        width: calc(100% - 60px - 28px);
        left: 60px + 14px;
    }
}

.assigned-to {
    padding-right: 8px;
    span:last-child {
        font-weight: bold;
    }
}

.payment-totals {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 8px;
    .item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .total-count {
            font-size: 24px;
            font-weight: bold;
            color: black;
            &.with-error {
                color: red;
            }
        }
        .count-label {
            font-size: 16px;
            color: #b3b3b3;
        }
    }
}

.email-list-container {
    overflow: auto; 
    border-right: 1px solid #00000026;
}

.select-email {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 54px;
    padding: 24px;
}

.email-list-viewer {
    .ui.loader.email-loader {
        margin-top: 300px;
    }
}

.table-edit-prevRows{
    td{
        background-color: #f2f2f2 !important;
        i {
            margin-left: 8px;
        }
    }
    &.first-row{
        td{
            border-top: 2px solid rgba(34,36,38,0.1) !important;
        }
    }
}

.table-edit-prevRows.selected-row{
    td{
        background-color: #227d9875  !important;
        color: white;
    }
}

.pdf-loader{
    color: white;
}

.pdf-document-loader-container {
    padding-top: 36px;
}

.assign-payment-container {
    display: grid;
    grid-template-columns: 30px 160px 1fr;
    align-items: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
    gap: 8px;
    .payment {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .payment-number {
        font-weight: 700;
        font-size: 20px;
        color: black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .payment-type {
        font-style: italic;
    }
    .users-assign {
        display: flex;
        flex-direction: row;
        color: black;
        gap: 4px;
    }
    .user {
        font-size: 16px;
        &.unassigned {
            font-style: italic;
            color: #A0A0A0;
        }
    }
}

.assign-user-selector {
    min-width: 200px;
}

.new-assign-select {
    p {
        margin-bottom: 4px;
    }
    margin-bottom: 12px;
}

.toggle-unassigned-button-container {
    margin-bottom: 4px;
}

.document-page {
    position: absolute;
    left: 0;
    top: 0;
}

.invoice-table-edit{
    .table-header-cell{
        &:first-child{
            width: 70px;
        }
    }
    td:first-child{
        width: 70px;
    }
}

.not-selectable {
    cursor: default !important;
}
