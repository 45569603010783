.wrapper {
  height: 100%;
  .container {
    width: 100%;
    height: 97%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .headerWrapper {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin: 0 auto;
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
