.smile {
  font-size: 70px;
  line-height: 95px;
}

.text {
  font-size: 30px;
  max-width: 500px;
  line-height: 40px;
}

.errorBox {
  width: 100%;
  height: 100vh;
  background-color: #0078d7;
  min-height: 600px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorContent {
  position: relative;
  top: -50px;
}
