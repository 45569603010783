@import 'variables';

.app-container {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  background-color: #EBEEF3;
  &.thin {
    padding-left: $sidebarWidthThin;
  }
  &.very.thin {
    padding-left: $sidebarWidthVeryThin;
  }
}
.noPadding {
  padding: 0 !important;
  padding-left: 60px !important;
}

.application-show-container {
  padding-bottom: 14px;
}

.gutter {
  background-color: #eee;
  position: relative;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  cursor: row-resize;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
}

.gutter.gutter-horizontal {
  cursor: col-resize;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}
