@font-face {
  font-family: 'LatoBlack';
  src: url(./../assets/fonts/Lato-Black.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoBlackItalic';
  src: url(./../assets/fonts/Lato-BlackItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoBold';
  src: url(./../assets/fonts/Lato-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoBoldItalic';
  src: url(./../assets/fonts/Lato-BoldItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoItalic';
  src: url(./../assets/fonts/Lato-Italic.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoLight';
  src: url(./../assets/fonts/Lato-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoLightItalic';
  src: url(./../assets/fonts/Lato-LightItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoRegular';
  src: url(./../assets/fonts/Lato-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoThin';
  src: url(./../assets/fonts/Lato-Thin.ttf) format('opentype');
}

@font-face {
  font-family: 'LatoThinItalic';
  src: url(./../assets/fonts/Lato-ThinItalic.ttf) format('opentype');
}

$orange: rgb(255, 99, 71);
$orange1: rgb(251, 174, 139);
$blue: rgb(34, 125, 152);
$blue1: rgb(10, 129, 139);
$green: rgb(50, 179, 148);
$yellow: rgb(248, 172, 88);
$red: rgb(237, 85, 101);
$gray: rgb(96, 96, 96);

$gray1: rgb(177, 177, 177);
$gray2: rgb(227, 227, 227);
$highlight: rgba(242, 243, 249, 0.5);

// text colors
$title-color: #041217;
$gray-body: rgb(144, 144, 144);

$orange-dark:rgb(232, 206, 193);
$green-dark:rgb(148, 203, 190);
$red-dark: rgb(232, 174, 180);
$yellow-dark:rgb(244, 215, 183);

$title-color: #041217;
$side-BG: #161619;
$alt-side-BG: #1f1f22;

//Sidebar width:
$sidebarWidthThin: 220px;
$sidebarWidthVeryThin: 60px;

//table
$topbar-bg: rgb(242, 242, 242);

$boxShadow: 0 2px 4px 0 gray-border(.12),0 2px 10px 0 gray-border();

$input-error: #d05f5f;

$avatar-size: 36px;

@function gray-border($alfa: .15) {
  @return rgba(34, 36, 38, $alfa);
}


.workItemsTable {
  margin-top: 18px;
  .ant-table-tbody:hover {
    cursor: pointer;
  }
  .ant-table-tbody tr:last-child td {
    border-bottom: none;
  }
  .ant-table {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    font-family: 'LatoRegular', serif;
    // border-bottom: none;
  }
  .ant-table-tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ant-table table {
    font-size: 11px;
    border-radius: 4px;
    th {
      color: #606060;
      font-weight: 700;
      &:first-child {
        padding-left: 40px;
      }
      &:last-child {
        padding-right: 40px;
      }
      &:nth-child(4), &:nth-child(2), &:nth-child(5), &:nth-child(3) {
        text-align: center;
      }
    }
    tr td {
      color: #808080;
      font-weight: 500;
      &:first-child {
        padding-left: 40px;
      }
      &:last-child {
        padding-right: 40px;
      }
      &:nth-child(4) {
        text-align: center;
      }
      &:nth-child(5) {
        text-align: center;
      }
    }
  }
  .ant-table th { background:  #F9FAFB }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 4px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 4px;
  }

}

// status colors
@mixin inProcess {
  &.in_process {
    color: #F8AC58;
    i {
      color: #F8AC58;
    }
  }
}
@mixin classification {
  &.classification {
    color: #F2711C;
    i {
      color: #F2711C;
    }
  }
}
@mixin needsAttention {
  &.needs_attention {
    color: #FFA300;
    i {
      color: #FFA300;
    }
  }
}
@mixin paused {
  &.paused {
    color: #8D9AAE;
    i {
      color: #8D9AAE;
    }
  }
}
@mixin rejected {
  &.rejected {
    color: #DA1F2C;
    i {
      color: #DA1F2C;
    }
  }
}
@mixin finalReview {
  &.final_review {
    color: #2474BB;
    i {
      color: #2474BB;
    }
  }
}
@mixin autoProcess {
  &.auto_process {
    color: #32B394;
    i {
      color: #32B394;
    }
  }
}
@mixin approved {
  &.approved {
    color: #00BF30;
    i {
      color: #00BF30;
    }
  }
}
@mixin completed {
  &.completed {
    color: #72CDF4;
    i {
      color: #72CDF4;
    }
  }
}
@mixin failed {
  &.failed {
    color: #ED5565;
    i {
      color: #ED5565;
    }
  }
}
@mixin completedToday {
  &.completed_today {
    color: #8ACA80;
    i {
      color: #8ACA80;
    }
  }
}
@mixin replied {
  &.replied {
    color: #4B87BC;
    i {
      color: #4B87BC;
    }
  }
}
@mixin factored {
  &.factored {
    color: #72CDF4;
    i {
      color: #72CDF4;
    }
  }
}

@mixin error {
  &.error {
    color: #3D3D3D;
    i {
      color: #3D3D3D;
    }
  }
}



.documentEventsWrapper, .PDFViewerTabs, .PDFListTabs {
  .ui.menu {
    justify-content: center;
  }
  .ui.segment {
    background-color: transparent;
    padding: 0;
  }
  .ui.attached.segment {
    border: none;
  }
  .ui.segment[class*="bottom attached"] {
    border-radius: 0;
  }
  .ui.secondary.menu .item {
    color: #2474BB;
    position: relative;
  }
  .ui.secondary.menu .active.item {
    background: #C8DCEE;
  }
  .ui.menu a.item:hover {
    background: #C8DCEE;
    color: #2474BB;
  }
  .ui.secondary.menu .active.item:hover {
    color: #2474BB;
  }
  .ui.menu .item > .label {
    background-color: #ED5565;
    border-radius: 25px;
    font-family: 'LatoRegular', serif;
    font-size: 11px;
    color: #F8F8F8;
    padding: 3px 5px;
    position: absolute;
    top: -2px;
    right: -2px;
  }
}

.rulesWrapper {
  .ui.checkbox label::before {
    border: 1px solid #227D98 !important;
  }
  .ui.radio.checkbox input:checked ~ label::after {
    background-color: #227D98 !important;
  }
  .ui.checkbox label {
    font-size: 12px !important;
  }
  // .statusFix {
  //   .ui.checkbox label {
  //     color: #32B394;
  //   }
  // }
  // .statusIgnore {
  //   .ui.checkbox label {
  //     color: #F8AC58;
  //   }
  // }
}

.cardsWrapper {
  margin-right: 16px;
  overflow-y: auto;
  max-height: 100%;
  overflow-x: auto;
  //padding-bottom: 20px;
}
.outputForm {
  .ui.input {
    width: 100%;
    height: auto;
  }
  input {
    width: 100%;
  }
}

.ant-select-selector {
  border-radius: 4px !important;
  height: 100% !important;
}
