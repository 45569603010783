@mixin input {
  font-family: 'LatoRegular', serif;
  text-transform: uppercase;
  font-size: 12px;
  color: #606060;
  padding: 10px 8px;
  background-color: #fff;
  border: 0.5px solid #DEDEDF;
  border-radius: 4px;
  outline: 0;
  &::-webkit-input-placeholder {color: #d2d2d2;}
  &::-moz-placeholder {color: #d2d2d2;}
  &:-ms-input-placeholder {color: #d2d2d2;}
  &:-moz-placeholder {color: #d2d2d2;}
}

.field {
  &:not(:first-child) {
    margin-top: 15px;
  }
  label {
    font-family: 'LatoRegular', serif;
    font-size: 12px;
    color: #606060;
    margin-bottom: 3px;
  }
  input {
    @include input();
    width: 100%;

  }
}
.payouts {
  margin-top: 80px;
}
.payout {
  display: flex;
  justify-content: space-between;
  &:not(:first-child) {
    margin-top: 10px;
  }
  input, select {
    @include input();
    width: 100%;
  }
  select {
    padding: 10px 8px;
  }
}
.select, .input {
  width: 50%;
}
.input {
  margin-left: 5px;
}
.select {
  margin-right: 5px;
}
.addPayoutButton {
  border: none;
  cursor: pointer;
  font-family: 'LatoRegular', serif;
  font-size: 20px;
  background-color: transparent;
}
.payoutButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.outputBox {
  background: #F6F8FA;
  padding: 16px;
  &:not(:first-child) {
    margin-top: 8px;
  }
  .payment {
    display: flex;
  }
}
.fieldLine {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  &:not(:first-child) {
    margin-top: 8px;
  }
  .fieldName {
    flex-shrink: 0;
    margin-right: 10px;
  }

  .antdSelectWrapper {
    width: 100%;
    flex-grow: 1;
    min-width: 0; // Crucial for text truncation
    
  }

  .additionalAmount {
    width: 220px;
    margin-right: 32px;
  }

  .additionalRemoveIconWrapper {
    display: flex;
    align-items: center;
    min-width: 21px;
    .additionalRemoveIconButton {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      }
  }
}
.notesField {
  align-items: flex-start;
}
.fieldName {
  display: block;
  font-family: 'LatoRegular', serif;
  color: #606060;
  font-size: 12px;
  min-width: 100px;
  font-weight: 600;
}
.shipmentField {
  align-items: center;
  & > .fieldName {
    font-size: 11px;
    line-height: 13px;
  }
}
.textAreaWrapper {
  width: 100%;
  .textArea {
    border-radius: 4px;
    padding: 4px 13px !important;
    &:focus::placeholder {
      color: #858585;
    }
    &:hover, &:focus {
      border-color: #85b7d9 !important;
      box-shadow: none;
    }
  }
  .warning {
    color: #ED5565;
    font-size: 12px;
  }
}
.outputBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

}
.outputBoxName {
  font-family: 'LatoRegular', serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #2474BB;
  display: flex;
  justify-content: space-between;
}
.outputBlockTitleWithButton {
  cursor: pointer;
  font-size: 12px;
  color:#2474BB;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    transform: scale(0.9);
  }
}
.readonlyField {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  font-family: 'LatoRegular', serif;
  color: #808080;
}
// .inputWrapper {
//   width: 100%;
// }
.outputForm {
  margin-right: 16px;
  max-height: 100%;
  overflow-y: auto;
}
.selectWrapper {
  width: 100%;
}
.test {
  padding: 0;
  margin: 0;
  width: calc(100% - 100px);
}
.shipmentInputWithSelect {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.miniSelect {
  max-width: 62px;
  min-width: 62px;
}

.additionalBlock {
  margin-top: 8px;
  max-height: 148px;
  overflow: auto;
}

.activeInput {
  -webkit-box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
  -moz-box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
  box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
  & > input {
    -webkit-box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
    -moz-box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
    box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
  }
}

.activeArea {
  -webkit-box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
  -moz-box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
  box-shadow: 0 0 5px 3px rgba(23,81,230,1) !important;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.additionalDropdownInfo {
  width: 300px;
  //height: 500px;
}
