.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  background-color: #fff;
  border: 0.5px solid #DEDEDF;
}
.submitButton, .downloadButton, .approvalButton, .groupingButton{
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.headerBatchInfo {
  display: flex;
  gap: 10px;
  align-items: center;
}
.approval {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DEDEDF;
  padding-bottom: 13px;
  padding-left: 30px;
  padding-right: 30px;
}
.batchId {
  font-family: 'LatoRegular', serif;
  font-size: 12px;
  color: #0E0E2C;
}
.workItemId {
  font-family: 'LatoRegular', serif;
  font-size: 12px;
  line-height: 14px;
  color: #0E0E2C;
}
.invoice {
  display: flex;
  justify-content: space-between;
  padding: 15px 26px 15px 30px;
  align-items: center;
  border-bottom: 1px solid #DEDEDF;
}
.workItemName {
  font-family: 'LatoRegular', serif;
  font-size: 14px;
  line-height: 17px;
  color: #0E0E2C;
  font-weight: 700;
}
.workItemEmail {
  font-family: 'LatoRegular', serif;
  font-size: 12px;
  line-height: 14px;
  color: #606060;
  margin-top: 5px;
  display: flex;
  gap:10px;
  .saveEmailButton {
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #227D98;
    visibility: hidden;
    &:active {
      transform: scale(0.9) !important;
    }
    &:hover {
      text-decoration: underline;
    }
    &Active {
      visibility: visible;
    }
  }
}
.invoiceData {
  font-family: 'LatoRegular', serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #606060;
}
.invoiceStatusText {
  font-family: 'LatoRegular', serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0E0E2C;
  margin-top: 5px;
}

.downloadLink {
  font-family: 'LatoRegular', serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #606060;
  margin-left: 28px;
}
.download {
  display: flex;
  align-items: center;
  margin-left: 10px;
  &.downloadButtonMargin {
    margin-left: 0;
  }
}
.approvalButton {
  background-color: #32B394 !important;
  color: #fff !important;
}
.groupingButton{
  height: 38px;
  background-color: #2474BB !important;
  color: #ffffff !important;
  &:hover {
    background-color: #0d528f !important;
  }
}

.emailsButton {
  background-color: transparent !important;
  border: 1px solid #2474BB !important;
  border-radius: 4px;
  position: relative;
  color: #2474BB!important;
  &:hover {
    background-color: #2474BB !important;
    color: white !important;
  }
  .emailBudge {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}

.showFunding {
  font-family: 'LatoRegular', serif;
  font-size: 14px;
  color: #2474BB;
  cursor: pointer;
  padding: 2px 5px;
  background-color: transparent;
  border: none;
}
.headerButtons {
  display: flex;
}


.viewerWrapper {
  display: flex;

}

.noDataFowViewer {
  margin: 20px 0;
  text-align: center;
}
.warning {
  font-size: 10px;
  line-height: 10px;
  color: #ED5565;
  margin-left: 28px;
  margin-top: 3px;
}

.lockedBlock {
  margin-left: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .userName {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    color: #0E0E2C;
  }
  .unlockButton {
    color: #227D98;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
