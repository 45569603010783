$blue: #2474BB;
$blue1: rgb(10, 129, 139);
$hover-bg: rgba(234, 239, 241, 0.5);
$selected-bg: rgba(234, 239, 241);
$cell-text: rgb(128, 128, 128);

.wiDocument {
  padding: 8px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  cursor: pointer;
  &:hover {
    background-color: $hover-bg;
  }
}

.wiDocumentExpanded {
  padding: 8px;
  cursor: pointer;
  border-bottom: 0;
  background-color: $selected-bg;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.wiDocumentRemoved {
  background-color: #EDF2F3;
  opacity: 0.5;
}

.wiDocumentHeader {
  display: flex;
  flex-direction: row;
}

.wiDocumentHeading {
  font-size: 14px;
  color: $blue;
  margin-bottom: 8px;
  span {
    display: inline-block;
    padding-right: 2px;
  }
}

.headerCaret {
  color: $blue;
}

.downloadIcon {
  color: $blue;
  &:hover {
    transform: scale(0.95);
  }
}

.fieldsCaret {
  padding-left: 4px;
}

.documentFieldsList {
  list-style-type: none;
  padding-inline-start: 0;
  padding: 8px;
  padding-left: 16px;
  background: #FFF;
  border-radius: 4px;
}

.fieldItem {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 4px;
  margin-bottom: 10px;
  .label {
    color: rgb(14, 14, 44);
    font-weight: 600;
    width: 120px;
    margin-bottom: 0;
    @media (min-width: 1400px) {
      width: 150px;
    }
  }
  .value {
    width: 100%;
    color: $cell-text;
    font-weight: 400;
    padding: 2px;
    &:hover {
      background-color: $hover-bg;
      border-radius: 2px;
    }
  }
  .blankValue {
    width: 100%;
    color: rgb(198, 198, 198);
    font-weight: 400;
    font-style: italic;
    padding: 2px;
    &:hover {
      background-color: $hover-bg;
      color: $cell-text;
      border-radius: 2px;
    }
  }
}

.noFields {
  font-style: italic;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .tools {
    display: flex;
    gap: 10px;

  }
  .headerTitle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.redoIcon {
  padding-right: 4px;
}
.action {
  &:hover {
    color: #2474BB !important;
    text-decoration: underline;
  }
  &:active {
    transform: scale(0.8);
  }
  & > svg {
    margin-top: 2px;
    fill: #929292;
    width: 15px;
  }
}
