.cardWrapper {
  background-color: #F3D5D8;
  font-family: 'LatoRegular';
  padding: 17px 33px;
  &:not(:last-child) {
    border-bottom: 1px solid #EBEEF3;
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.cardTitle {
  font-size: 12px;
  color: #ED5565;
  font-weight: 600;
  position: relative;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #ED5565;
    border-radius: 25px;
    position: absolute;
    top: 6px;
    left: -20px;
  }
}
.cardStatuses {
  display: flex;
  margin-top: 8px;
  margin-left: -22px;
  & > div {
    &:not(:first-child) {
      margin-left: 26px;
    }
  }
}
.statusReject, .statusIgnore, .statusFix {
  color: #fff;
  // padding: 0px 15px;
  border-radius: 2px;
}
// .statusReject {
//   background-color: #ED5565;
// }
// .statusIgnore {
//   background-color: #F8AC58;
// }
// .statusFix {
//   background-color: #32B394;
// }
