@import 'variables';

/*inputField*/
.datepickerContainer .input{
    width: 100%;
}
.inputField-label{
    color: black;
    margin: 4px 0;
}
.inputField-textArea[disabled] {
  opacity: .45;
  background: #f2f2f2 !important;
}
.inputField-error{
  font-size: 12px;
  margin-top: -8px;
  padding-left: 2px;
  color: $input-error;
}
.dropdown-error{
  font-size: 12px;
  padding-left: 2px;
  color: $input-error;
}
.inputDateField-error{
  font-size: 12px;
  margin-top: 0;
  padding-left: 2px;
  color: $input-error;
}
.inputField-passError{
  color: $input-error !important;
  background: #fff6f6 !important;
  border-color: #e0b4b4 !important;
}
.errorDate input{
  background: #fff6f6 !important;
  border-color: #e0b4b4 !important;
}

.form-error {
  font-size: 12px;
  color: $input-error;
  margin-top: 3px;
  &.inline {
    margin-bottom: 1em;
  }
}

.user-loader {
  height: calc(100vh - 28px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  label {
    min-width: 200px;
  }
  .field-container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.text-input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.select-container {
  width: 100%
}
//.table-button{
//  border: #2474BB !important;
//  border: 2px solid red;
//  box-sizing: border-box;
//  border: 0;
//  border-radius: 4px;
//  font-family: inherit;
//  font-size: 1rem;
//  padding: 0.5em 0.8em;
//  cursor: pointer;
//  text-align: center;
//
//  >i{
//    margin-right: 0.5em;
//  }
//
//  &:focus{
//    outline: 0;
//  }
//
//  &:disabled{
//    cursor: not-allowed;
//    opacity: 0.45;
//  }
//
//  &.success{
//    background: rgb(111, 207, 151);
//    color: white;
//    &:hover,
//    &:active,
//    &:focus{
//      background: rgb(111, 217, 151);
//    }
//  }
//
//  &.fail{
//    background: rgb(235, 87, 87);
//    color: white;
//    &:hover,
//    &:active,
//    &:focus{
//      background: rgb(245, 87, 87);
//    }
//  }
//
//  &.primary{
//    background: $blue;
//    color: white;
//    &:hover,
//    &:active,
//    &:focus{
//      &:not(:disabled) {
//        background: $blue1;
//      }
//    }
//  }
//
//  &.primary-link{
//    background: transparent;
//    color: $blue;
//    &:hover,
//    &:active,
//    &:focus{
//      color: $blue1;
//      text-decoration: underline;
//    }
//  }
//
//  &.secondary{
//    background: white;
//    color: $blue;
//    border: 1px solid $blue;
//    &.secondary:hover,
//    &.secondary:active,
//    &.secondary:focus{
//      color: $blue1;
//      border-color: $blue1;
//    }
//  }
//
//  &.spinner{
//    margin-bottom: 2px !important;
//  }
//}

.collapsable-container{
  background-color: white;
  position: relative;
  .active.content {
    width: 100%;
    padding-top: 0 !important;
  }
}

.collapsable-container .active.content {
  height: 100%;
  overflow: auto;
}

.collapsable-panel-handler{
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 0;
  display: flex;
  align-items: center;
  &.pdf-collapsable {
    display: none; // viewer callapsable
  }
}

.collapsable-panel-handler > i{
  transition: transform .1s ease,opacity .1s ease,-webkit-transform .1s ease;
}
.collapsable-panel-handler.active > i{
  transform: rotate(180deg);
}

.pdf_viewer_container{
  position: absolute;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5a5a5ac9;
  .react-pdf__Page{
    margin: 24px;
  }
  .react-pdf__message--error {
    width: 100%;
    padding: 14px;
    margin-top: -50px;
  }
}

.pdf-toolbar{
  background-color: #2C2C2C;
  padding: 8px 16px;
  box-shadow: 0 4px 4px 0;
  // position: absolute;
  z-index: 2;
  width: 100%;
  height: 52px;
  .input{
    margin: 0 8px;
    width: 60px;
  }
  button, a{
    color: #f9f9fa !important;
    background: none !important;
    &:hover{
      background-color: #666667 !important;
    }
  }
  .button_separator{
    padding: 12px 0;
    width: 1px;
    background-color: #0000004d;
    margin: 4px 0;
  }
}

.full-height-loader {
  height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

// container for viewer events
.events-container{
  &.hyperscience {
    // min-width: 250px;
    width: 275px;
    // max-width: 275px;
    // height: calc(100vh - 143px);
    overflow: auto;
  }
}

.companies-header{
  margin-top: 12px;
}

.companies-header-text{
  width: 300px;
  font-size: 24px;
  color: black;
  font-weight: 600;
}

.popover{
  position: absolute;
  z-index: 2;
  // right: 14px;
  // &.last{
  //   right: 14px
  // }
}

.cover{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.colorbutton{
  cursor: pointer;
  height: 12px;
  width: 24px;
  margin-right: 4px;
  border: 1px solid rgba(34,36,38,.15);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    box-shadow: 0 4px 8px 0 #0000004d, 0 0 8px #00000026;
  }
  &.card {
    height: 24px;
  }
}

.colorContainer{
  display: flex;
  font-size: 12px;
  align-items: center;
  color: black;
  span{
    width: 120px;
  }
}

.legendSection{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  color: rgb(161, 161, 161);
  span {
    margin-top: 8px;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
  }
}

.sorter-wrapper {
  position: relative;
}

.sorter-item {
  display: flex;
  align-items: center;
  gap: 0 8px;
  list-style: none;
  min-width: 210px;
  background: white;
  border-radius: 5px;
  margin: 5px auto;
  padding: 8px;
  color: black;
  cursor: grab;
  &:hover {
    background: rgb(240, 238, 240);
  }
}

i.icon.sorter-bar {
  color: #adadad;
}

.sorter-container, .preSaved-filters-container {
  position: absolute;
  top: 42px;
  right: 0;
  z-index: 3;
}
.preSaved-filters-container {
  padding: 10px 10px 100px;
}

.sorter {
  background: #d3d3d3;
  padding: 8px;
  border-radius: 8px;
  margin: 0;
}

.empty-app-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  color: rgb(161, 161, 161);
  font-size: 16px;
  :not(:first-child) {
    margin-top: 8px;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
  }
}

.react-select-new-item {
  span:first-child{
    color: red;
    font-style: italic;
  }
}

.icon-picker-container {
  position: relative;
  i.icon.circular {
    cursor: pointer;
    box-shadow: none;
    -webkit-box-shadow: none;
    &:hover {
      background-color: rgba(199, 199, 199, 0.6);
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

.icon-picker {
  position: absolute;
  top: -176px;
  left: -88px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 10px, rgba(0, 0, 0, 0.16) 0 2px 5px;
  padding: 16px;
  text-align: center;
  z-index: 2;
  background-color: white;
}

.select-icon {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px 12px;
  align-items: center;
  justify-content: center;
}

.grow-icon {
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.5);
    color: black;
  }
}
.react-resizable-handle {
  display: block;
  width: 5px;
  height: 5px;
  background-color: red;
  position: absolute;
  right: -10px;
  bottom: -10px;
  cursor: nwse-resize !important;
}

.react-resizable {
  position: absolute;
  top: 0;
}

.Toastify__toast-body {
  white-space: pre-line;
}
