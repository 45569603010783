.wiDocumentsListContainer {
  flex-basis: 70%;
  padding-right: 12px;
  min-width: 375px;
  height: 100%;
  overflow: auto;
}

.wiDocumentsList {
  list-style-type: none;
  padding-inline-start: 0;
  overflow-y: auto;
}
