.user-banner{
    height: 150px;
    margin: 0 -28px;
}
#profile-image{
    width: 200px;
    height: 200px;
    background-position: center center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 4px 8px 13px 0px #22242626;
}
.account-settings{
    margin-top: -54px;
}
.profile-image-container{
    position: relative;
    display: flex;
    align-items: center;
    .button-action{
        position: absolute;
        z-index: 2;
        left: 160px;
        top: 150px;
    }
    .image-text{
        margin-left: 32px;
        color: #606060;
        .name{
            font-size: 24px;
            font-weight: 700;
        }
        .email{            
            margin-top: 8px;
            font-size: 14px;
        }
    }
}