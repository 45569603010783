.groupHeaderWrapper {
  display: flex;
  align-items: center;
  background-color: #BDD8E0;
  border-radius: 4px 4px 0px 0px;
  justify-content: space-between;
  padding: 5px 15px;
}
.idNumber {
  color: #606060;
  font-family: 'LatoRegular';
  font-size: 12;
  font-weight: 800;
}
.statusButton {
  pointer-events: none;
  margin: 0 !important;
}
.groupBodyWrapper {
  padding: 8px;
  background: #EBEEF3;
  border-radius: 0px 0px 4px 4px;
  min-height: 158px;
}
.addGroupButton {
  background-color: transparent !important;
  color: #227D98 !important;
  padding-left: 0px !important;
  margin-top: 10px !important;
}
.documentWrapper {
  &:not(:first-child) {
    margin-top: 15px;
  }
}
.documentsWrapper {
  max-height: 500px;
  overflow-y: auto;
}
.groupsWrapper {
  margin-top: 8px;
  max-width: 540px;
}
