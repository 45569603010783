.card-widget {
  min-width: 228px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 8px;
  padding: 18px;
  gap: 18px;
  background-color: white;
  box-shadow: 0px 2px 4px #00000040;
  .icon-container {
    padding: 12px;
    border-radius: 50%;
    background-color: #adadad;
    width: 63px;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-widget-icon {
    color: black;
  }
  .value-container {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
    .value {
      color: rgb(79, 79, 79);
      font-weight: 700;
      font-size: 28px;
    }
    .label {
      color: rgb(165, 161, 161);
      font-size: 12px;
    }
  }
}

.cards-widget-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row wrap;
  gap: 8px;
  overflow-y: auto;
  padding-bottom: 4px;
}

.card-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}