.addGroupButton {
  .icon {
    color: #227D98;
    margin-right: 10px;
  }
}
.batchPDFviewerTabs {
  .ui.menu {
    display: none;
  }
}
