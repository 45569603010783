.logo-fields-container {
  display: flex;
  justify-content: flex-start;
  gap: 0 8px;
  > * {
    flex: 1 1 0px;
  }
}

.company-logo-preview{
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  max-width: 150px;
  width: 100%;
}

.logo-field {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-direction: column;
}

.logo-size-disclaimer {
  font-size: 12px;
  font-style: italic;
}

.no-logo-disclaimer{
  font-style: italic;
}

.submit-button {
  text-align: right
}