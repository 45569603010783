.filters {
  width: 70%;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  .filterItem {
    width: 201px !important;
  }
}
.selectMenu {
  display: flex;
  padding: 0 10px 4px;
  gap: 3px;
  .filterButton {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #227D98;
    &:hover {
      text-decoration: underline;
    }
    &:active {
      transform: scale(0.8) !important;
    }
  }
}
