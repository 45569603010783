.card {
  width: 80px;
  height: 94.6px;
}
.page {
  text-align: center;
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 7px;
  margin-top: 4px;
}
.pageWrapper {
  padding: 2px;
  border: 1px solid transparent;
  &Active {
    border: 1px solid #2fbfec;
    background-color: #6dc2dd;
    border-radius: 2px;
  }
}
