.widget-card {
  min-width: 200px;
  padding: 8px 12px;
  margin: 0 4px 8px 0;
  border-radius: 8px;
  background: white;
  box-shadow: #22242626 0px 1px 2px 2px;
  h4 {
    margin-bottom: 8px;
  }

  ul {
    padding-inline-start: 0;
    list-style: none;
    margin: 0;
    li {
      margin-bottom: 8px;
    }
  }
}

.column .preview-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.dashboard-widget {
  box-shadow: #22242626 0px 1px 2px 2px;
  border-radius: 8px;
  background-color: #F8F8F8;
  padding: 8px;
  margin-bottom: 14px;
}

.widget-toolbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.graph-title {
  color: #000;
  font-weight: bold;
  padding-left: 4px;
  font-size: 16px;
  &.preview {
    font-size: 12px;
    padding-left: 0;
  }
}

.widget-form-options {
  width: 100%;
}

.edit-filter-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 4px 12px;
}

.customize-cards {
  width: 75%;
  padding: 14px 28px;
  text-align: center;
}

.card-opt {
  display: grid;
  grid-template-columns: 70% 15% 15%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  p.card-name {
    color: #4F4F4F;
    margin-bottom: 0;
  }
}

.color-picker-container {
  width: 100%;
  max-width: 30px;
}

.widget-loader {
  height: 350px;
}