.wrapper{
  position: relative;

  .button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #227D98;
    &:hover {
      text-decoration: underline;
    }
    &:active{
      transform: scale(0.7);
    }
  }
  .content {
    overflow: hidden;
    position: absolute;
    padding: 8px;
    width: 300px;
    height: 151px;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0 6px 20px rgba(96, 96, 96, 0.25);
    z-index: 2;
    display: flex;
    flex-direction: column;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .closeButton {
        cursor: pointer;
        &:active{
          transform: scale(0.7);
        }
        &:hover {
          svg {
            fill: #0e0e2c;
          }
        }
      }
      .contentTitle {
        color: #227D98;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .text {
      overflow-y: auto;
      height: 100%;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      /* or 145% */


      /* header table text */

      color: #606060;

    }
  }

}
