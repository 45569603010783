.wrapper {
  line-height: unset;
  width: 320px;
  height: 220px;
  box-shadow: 0 1.850000023841858px 6.25px 0 rgba(0, 0, 0, 0.19), 0 0.5px 1.75px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  background:  #FFF;
  padding: 16px;


  .title {
    color: #123B60;
    text-align: center;
    font-family: Lato, serif;
    font-size: 16px;
    font-weight: 900;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 44px;
  }

  .value {
    color: #123B60;
    text-align: center;
    font-family: Lato, serif;
    font-size: 64px;
    font-weight: 900;
    line-height: 64px;
  }

  .subtitle {
    color: #3D3D3D;
    font-family: Lato, serif;
    text-align: center;
    font-size: 14px;
    //font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-top: 8px;
  }

  .upArrow, .downArrow, .defaultArrow {
    font-size: 23px;
  }
  .upArrow {
    color: #00BF30;
  }

  .downArrow{
    color: #DA1F2C;
  }
  .percentBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    .percent {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #123B60;
      margin-right: 12px;
    }
  }
}

.green {
  color: #00BF30 !important;
}

.red {
  color: #DA1F2C !important;
}
