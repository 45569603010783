.tr {
  > td  {
    padding: 10px;
    text-align: center;
}
  &:not(:last-child) {
    border-bottom: 1px solid lightgrey;
  }
}

.sticky {
  position: sticky;
  left: 0;
  background-color: white;
}

.buttonsWrapper {
  padding-bottom: 0;
  padding-top: 20px;
  gap: 10px;
  display: flex;
  justify-content: flex-end;
}
.submitRemoveButton {
  background-color: #227d98 !important;
  color: #ffffff !important;
  &:hover {
    background-color: #19596c !important;
  }
}
