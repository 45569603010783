@import 'variables';

.main-activity-container {
  height: calc(100vh - 57px);
  margin: 0 -28px;
}

.activity-list-container {
  padding-bottom: 28px;
}

.update-activity {
  height: 85px;
  padding: 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray-border();
  label {
    font-size: 13px;
    color: black;
    font-weight: 700;
    padding-bottom: 2px;
    display: inline-block;
  }
  > div {
    padding: 0 14px;
    &.buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 0 14px;
    }
  }
}

.activity-select--is-disabled {
  min-width: 175px;
}

.activity-select__menu{
  z-index: 2 !important;
}

.activity-with-events{
  display: flex;
  flex-direction: row;
  .activity-container {
    flex: 4;
    width: calc(100% - 200px);
    // border-right: 0.5px solid #00000026;
  }
}
