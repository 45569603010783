.button {
  position: relative;
  font-family: 'LatoRegular', serif;
  font-size: 14px;
  background-color: #2474BB !important;
  &:hover:not(.disabled) {
    background-color: #0d528f !important;
  }
}
.disabled {
  background-color: rgb(36, 116, 187) !important;
  opacity: 0.45;
  cursor: default !important;
}
.icon {
  position: relative;
  top: 1px;
}
