$docs-bg: rgba(235, 238, 243, 0.44);
$header-color: #2474BB;

.wiDocuments {
  flex-grow: 1;
  background-color: $docs-bg;
  padding: 12px 0 0 12px;
}

.docsHeading {
  font-size: 16px;
  font-weight: 600;
  color: $header-color;
  margin-bottom: 0;
}

.addDocumentSelect {
  background-color: #2474BB !important;
  &:hover {
    background-color: #0d528f !important;
  }
}
.readOnlyDropdown {
  cursor: not-allowed !important;
  background-color: #B5D1D9 !important;
  &:hover {
    background-color: #B5D1D9 !important;
  }
}
.wiDocumentsListContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 12px;
  min-width: 375px;
  margin-bottom: 14px;
  align-items: center;
}
.wiDocumentsScroll {
  display: flex;
  flex-direction: column;
}
.submitUploadButton {
  background-color: #227d98 !important;
  color: #ffffff !important;
  &:hover {
    background-color: #19596c !important;
  }
}

.dragAndDropArea {
  height: 109px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.5px dashed #cccccc;

  .activeUploadIcon {
    & path:nth-child(2) {
      fill: #227d98;
    }
  }
  .uploadText {
    margin-top: 8px;
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .activeUploadText {
    color: #227d98;
  }

  .removeIcon {
    cursor: pointer;
    transform: translate(26px, -4px);
  }

  .removeIconTransparent {
    visibility: hidden;
  }
}

.dropZone {
  border: transparent !important;
  & > div {
    display: none;
  }
}

.headerText {
  display: flex;
  flex-direction: column;
}
.showDeleted {
  display: flex;
  gap: 8px
}
