.wrapper {
  .header {
    font-family: 'Lato', serif;
    padding: 15px 24px;
    background: #ffffff;
    border-bottom: 0.5px solid #dededf;
    display: flex;
    justify-content: space-between;

    .headerLeft {
      display: flex;
      gap: 24px;
      font-size: 24px;
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        text-transform: uppercase;
        color: #0e0e2c;
      }
      .companyName,
      .wiNumber {
        font-family: 'Lato', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        text-transform: uppercase;

        color: #808080;
      }
    }
    .headerClose {
      color: black;
      cursor: pointer;
      font-size: 14px;
      margin-top: 3px;
      &:active {
        transform: scale(0.8) !important;
      }
    }
  }

  .content {
    height: calc(100vh - 52px);
    display: flex;
    gap: 16px;
    padding: 20px;
    .contentLeft {
      width: 50%;
      height: 100%;
      background: #ffffff;
      border-radius: 4px;
      padding: 8px;
      overflow-x: auto;
    }
    .contentRight {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      //background-color: green;

      .filtersHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;


        .addButton {
          background-color: #227d98 !important;
          &:hover {
            background-color: #19596c !important;
          }
          color: #ffffff !important;
        }
      }
    }
    .workItems {
      background: #ffffff;
      border-radius: 4px;
      padding: 8px;
      overflow-x: auto;
      .emptyMessage {
        text-align: center;
      }
    }
  }
}
.loadingWrapper {
  //padding: 20px;
  height: 89px;
  //padding: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightbox {
  max-height: 150px !important;
}
