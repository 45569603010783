.wrapper {
  text-align: right;

  .fieldsWrapper {
    width: 450px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15px;

    font-family: "LatoRegular", serif;
    color: #606060;
    font-size: 16px;
    min-width: 100px;
    font-weight: 600;
  }

  .fieldsContent {
    width: 100%;
    border: 0.5px solid #DEDEDF;
    padding: 20px;
    background-color: white;
    border-radius: 8px;

    .fieldsHeader {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .values {
        display: flex;
        gap: 10px;
      }
    }
  }

  .buttonIcon {
    margin-left: 5px !important;
  }
}
