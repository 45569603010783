@import 'variables.scss';
$document-bg: #1F1F22;
$pdf-bg: #5a5a5ac9;
$field-success: rgb(65, 184, 99);
$field-warning: rgb(242, 113, 28);
$bounding-box-border: #3365DE;

#hs-widget {
  position: relative;
  min-width: 1000px;
  min-height: 700px;
  height: calc(100vh - 144px);
}

.document-viewer {
  background: $pdf-bg;
  height: 100%;
  // max-width: 480px;
}

.document-container {
  position: relative;
  height: 100%;
}

.hs-document {
  width: 100%;
  background: white;
}

.no-shadow {
  box-shadow: initial !important;
}

.scrollable-document{
  overflow: auto;
  padding: 14px;
  height: calc(100% - 65px); // viewer height
}

.bounding-box{
  cursor: pointer;
  background: rgba(148, 172, 209, 0.3);
  border: 1px solid $bounding-box-border;
  &.selected{
    background: rgba(51, 101, 222, 0.3);
    outline: 0.2em solid $bounding-box-border;
  }
  &.exception{
    background: rgba(245, 20, 20, 0.15);
    border: 1px solid rgb(251, 105, 105);
    &.selected {
      background: rgba(245, 20, 20, 0.3);
      outline: 0.2em solid rgb(251, 105, 105)
    }
  }
}

.box-input {
  background: rgb(196, 196, 196);
  border-radius: 3px;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 4px;
  > input {
    border-radius: 2px;
    background: rgb(226, 226, 226);
    border: 0;
    padding: 6px;
    &:focus {
      outline: 0;
    }
    &:disabled {
      cursor: default;
    }
  }
  button {
    .ui.icon {
      opacity: 1;
    }
    background-color: inherit !important;
    &:hover, &:active {
      background-color: white !important;
    }
  }
}

.hs-fields {
  width: 100%;
  position: relative;
  overflow: hidden;
  .search-section {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray-border();
    padding: 14px;
    gap: 4px;
    .search-select {
      width: 200px;
    }
  }
  ul {
    margin: 0;
    padding-left: 0;
  }

  li.hs-field {
    list-style: none;
    display: flex;
    flex-direction:column;
    cursor: pointer;
    > * {
      padding: 14px;
      padding-left: 24px;
    }
    .field-expanded {
      padding-left: 64px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > * {
        width: 100%;
        &:last-child {
          text-align: right;
        }
      }
      background-color: rgba(34, 125, 152, 0.25);
      color: rgb(14, 14, 44);
      &.normalized {
        background-color: rgba(34, 125, 152, 0.5);
      }
    }
    .field-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 24px;
        width: 40px;
      }
      .field-name {
        flex-grow: 1;
        color: rgb(96, 96, 96);
      }
      .field-value {
        text-align: right;
      }
      &.complete {
        .field-value {
          color: $field-success;
        }
      }
      &.exception{
        border: 1px solid $field-warning;
        .field-value {
          color: $field-warning;
        }
      }
    }
    &.selected {
      .field-container {
        background-color: rgba(34, 125, 152, 1);
        .field-name {
          color: white;
        }
      }
      &.exception {
        border: 0
      }
    }
  }
}

.hs-table-container {
  background: white;
  position: relative;
  overflow: auto;
}

.hs-table {
  th:first-child {
    width: 40px;
  }
  td {
    color: rgb(96, 96, 96) !important;
    cursor: pointer;
    &:first-child {
      padding-left: 24px !important
    }
    &:last-child {
      padding-right: 10px !important;
    }
    &.cell__with-exception {
      color: $field-warning;
    }
  }
  tr.selected-row {
    background: rgba(34, 125, 152, 0.25);
  }
}

.hs-input {
  &.disabled {
    cursor: default;
  }
  > input {
    text-align: right !important;
  }
}

.cell-exception {
  color: $field-warning;
}

.cell-blank {
  color: #a9a9a9;
  font-style: italic;
}

.correct-document-container {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.page-error-container {
  background-color: white;
  height: 100%;
  padding: 28px 14px;
  text-align: center;
}

.hs-list-heading {
  margin: 0;
  color: black;
  padding: 10px 14px 5px;
  font-weight: 700;
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  color: rgb(161, 161, 161);
  span {
    margin-top: 6px;
    font-size: 16px;
  }
}

// fields height
.field-list-container {
  height: calc(100% - 20px);
  overflow: hidden auto;
}

.layout-header {
  padding: 12px 14px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.empty {
    justify-content: flex-start;
    cursor: default;
  }
  .name-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 6px;
    align-items: center;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
    color: #303030;
  }
  .quantity {
    background-color: #d7d7d7;
    color: #303030;
    font-size: 12px;
    text-align: center;
    padding: 3px 6px;
    border-radius: 8px;
  }
  i {
    color: #646464;
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}

.hs-empty-search {
  &.ui.negative.message {
    margin: 0 !important;
    border-radius: 0 !important;
  }
}

.wi-doc-viewer {
  flex-grow: 1;
}

.PDFViewerTabs, .PDFListTabs {
  height: 100%;
  width: 100%;
  // max-width: 380px;
  & > div {
    height: 100%;
    // height: calc(100% - 70px);
  }
  .ui.segment {
    // height: calc(100vh - 70px);
    // height: 100%;
    // height: calc(100% - 51px);
    height: calc(100% - 54px);
    border: none;
    box-shadow: none;
    margin-top: 0;
  }
  .ui.menu {
    margin-bottom: 10px;
    margin-top: 0;
    padding-top: 8px;
  }
}

.thin {
  .hs-data-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 0 4px;
    height: 100%;
    .PDFListTabs {
      min-width: 190px;
      max-width: 450px;
      //min-height: 85vh;
      @media (max-width: 1300px) {
        max-width: 250px;
      }
      @media (max-width: 1400px) {
        max-width: 275px;
      }
      @media (max-width: 1500px) {
        max-width: 300px;
      }
      @media (max-width: 1600px) {
        max-width: 350px;
      }
    }
    .wi-documents-container {
      display: flex;
      height: 100%;
    }
    .wi-document-list-container {
      padding-right: 12px;
      height: 100%;
      overflow: auto;
      width: 100%;
      min-width: 265px;
      @media (max-width: 1350px) {
        max-width: 275px;
      }
      @media (max-width: 1400px) {
        max-width: 325px;
      }
      @media(max-width: 1600px) {
        max-width: 450px;
      }
    }

    .document-viewer {
      width: 100%;
      @media (max-width: 1350px) {
        max-width: 480px;
      }
      // @media (max-width: 1400px) {
      //   max-width: 480px;
      // }
    }
  }
}

button.ui.button.full-width-button {
  @media (max-width: 1400px) {
    display: none;
  }
}
