$dd-text: rgb(80, 88, 108);

.box__input {
  background-color: rgb(239, 243, 252);
  padding: 50px 20px;
  margin-bottom: 8px;
  border: 2px dashed $dd-text;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $dd-text;
  font-size: 16px;
  gap: 4px;
  cursor: pointer;
  &.disabled {
    cursor: default;
  }
  &.is-dragover {
    outline-offset: -20px;
    outline-color: #c8dadf;
    background-color: #fff;
  }
  &.uploading {
    opacity: 0.1;
    cursor: default;
  }
}

.uploading-spinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, .1);
}

.uploaded-files {
  padding-left: 0;
  margin: 8px 0;
  width: 100%;
  cursor: default;
  .uploaded-file {
    border: 1px solid gray-border();
    border-radius: 8px;
    box-shadow: #22242626 0px 1px 2px 2px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
    background-color: #FFF;
    font-size: 14px;
  }
  .delete-icon {
    cursor: pointer;
    margin-left: 8px;
    color: red;
    &:hover
    &:active {
      color: lightcoral;
    }
  }
}