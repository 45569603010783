.authWrapper {
  display: flex;
  min-height: 700px;
  justify-content: center;
  height: 100vh;
  background-color: #2474BB;
}
.logoWrapper {
  text-align: center;
  margin-bottom: 94px;
}
.auth {
  padding: 160px 170px 114px 170px;

}
.serverErrorWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.errorIcon {
  width: 16px;
  height: 16px;
}
.companyLogo {
  max-width: 203px;
}
.authTitle {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 34px;
  color: #FFFFFF;
  margin-bottom: 14px;
}
.authDetails {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}
.authForm {
  margin-top: 26px;
  width: 366px;
}

.errorField {
  border-color: red;
}
.email, .password {
  width: 100%;
  border: 1px solid #DEDEDF;
  border-radius: 4px;
  padding: 9px 15px;
  font-family: 'LatoRegular', sans-serif;
  font-size: 14px;
  line-height: 17px;
  outline: 0;
  color: #0E0E2C;
  &:focus {
    border-color: #227D98;
  }
  &::-webkit-input-placeholder {
    font-family: 'LatoRegular', serif;
    font-size: 14px;
    line-height: 17px;
    color: #808080;
  }
  &::-moz-placeholder {
    font-family: 'LatoRegular', serif;
    font-size: 14px;
    line-height: 17px;
    color: #808080;
  }
  &:-ms-input-placeholder {
    font-family: 'LatoRegular', serif;
    font-size: 14px;
    line-height: 17px;
    color: #808080;
  }
  &:-moz-placeholder {
    font-family: 'LatoRegular', serif;
    font-size: 14px;
    line-height: 17px;
    color: #808080;
  }
}
.fieldPassword {
  margin-top: 19px;
}
.submitButton {
  width: 100%;
  padding: 16px 24px;
  max-height: 50px;
  cursor: pointer;
  margin-top: 23px;
  border-radius: 0 24px;
  background-color: #72CDF4;
  border: 1px solid #72CDF4;
  color: #FFFFFF;
  font-family: 'Helvetica', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  &:disabled {
    background: #B8E6F9;
  }
}
.errorText {
  min-height: 20px;
  font-family: 'LatoRegular', serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #EAB4B8;
  margin-left: 5px;
}
.forgotButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.forgotButton {
  border: 0 !important;
  background-color: transparent !important;
  color: white !important;
  &:hover{
    color: #72CDF4 !important;
  }
}
.fieldWrapper {
  position: relative;
}
.fieldError {
  .email, .password {
    border-color: #ED5565;
  }
  &::after {
    content: '';
    background-image: url('../../assets/icons/error_circle.svg');
    height: 24px;
    width: 24px;
    position: absolute;
    top: 7px;
    right: 17px;
    background-repeat: no-repeat;
  }
}
