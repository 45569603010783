.UpperBar-container{
    background: #FFF;
    height: 56px;
    margin: -27px -28px     0 -28px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
    .title-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 24px;
      width: calc(100% - 56px);
      gap: 0 12px;
    }
    .title{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .title-text{
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .subtitle{
            font-size: 12px;
            font-weight: 400;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .back-button{
        cursor: pointer;
        height: 100%;
        width: 56px;
        border-left: 0.5px solid rgba(0, 0, 0, 0.15);
        &:hover{
            background-color: #fbfbfb;
        }
    }
}

.centering-loader {
  margin-right: 8px !important;
  z-index: 0 !important;
}

.nav-container{
  display: flex;
  flex-direction: row;
  height: 100%;
}

.nav-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  padding: 8px;
}