// styles that override the defaults from semantic-ui will go here
@import 'variables';

$current-page-bg: rgb(242, 113, 28);

body {
  color: $gray-body !important;
}
.react-select__loading-indicator{
  span{
    font-size: 4px !important;
  }
}

.react-select__loading-indicator{
  span{
    font-size: 4px !important;
  }
}

.desktop-table-container {
  .ui.very.basic.table {
    color: $gray-body;
    thead > tr > th:first-child {
      padding-left: 0 !important;
    }

    tbody > tr > td{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}


.ui.sidebar.menu.vertical.thin {
  overflow: visible !important;
  border: 0;
}

.ui.avatar.image{
  margin-right: 0 !important
}

.ui.pagination.menu {
  border: 0 !important;
  box-shadow: none !important;
  a.item {
    border: 0 !important;
    border-radius: 2em;
    &:first-child,
    &:last-child {
      border-radius: 0;
    }
    &.active {
      background-color: $current-page-bg;
      color: white;
    }
    &::before {
      background-color: inherit;
      width: 0;
    }
  }
}

a.ui.button,
button.ui.button {
  font-weight: 400 !important;
  &.primary {
    background-color: $blue !important;
    color: white !important;
    &:hover {
      background-color: $blue1 !important;
    }
  }

  &.basic {
    color: $blue !important;
    border: 1px solid $blue !important;
    box-shadow: none;
    background: white !important;
    &:hover,
    &:active,
    &:focus {
      color: $blue1 !important;
      border-color: $blue1 !important;
      box-shadow: none;
    }
  }
}

.ui.input.disabled {
  background-color: rgb(242, 242, 242) !important;
  border-color: rgb(227,227,227) !important;
  opacity: 1 !important;
  color: rgb(160, 160, 160) !important;
}

.ui.disabled.input > input, .ui.input:not(.disabled) input[disabled] {
  background-color: rgb(245, 245, 245) !important;
  color: rgba(0, 0, 0, 0.25);
}

.ui.floating.dropdown {
  &.right > .menu {
    right: 0;
    left: auto;
  }
}

.required.field.inline-field.error {
  margin: 0;
  label {
    padding-top: 1em;
  }
}

.sorter-item {
  .ui.toggle.checkbox input:focus:checked~.box:before,
  .ui.toggle.checkbox input:focus:checked~label:before,
  .ui.toggle.checkbox input:checked~.box:before,
  .ui.toggle.checkbox input:checked~label:before {
    background-color: hsl(194deg, 63% ,47%) !important;
  }
}

button.ui.basic.button.button-link {
  background: transparent !important;
  color: $blue !important;
  padding: 0 !important;
  border: 0 !important;
  &:hover{
    color: $blue1;
    text-decoration: underline;
  }
}

.no-border-tabs{
  .segment {
    border: none !important;
    box-shadow: none !important;
  }
  .ui.secondary.pointing.menu {
    a.item:not(.active){
      color: #909090;
      &:hover {
        color: black;
      }
    }
  }
}
