.totalField {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  .fieldName {

  }
  .addButton {
    cursor: pointer;
    font-size: 12px;
    color:#2474BB;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
    &:active {
      transform: scale(0.9);
    }
  }
}

.splitFieldsWrapper{
  padding: 10px 30px;
  .spitField {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .type {
      width: 45%;
    }

    .value {
      width: 45%;
    }
    .remove {
      width: 10%;
      margin-left: 27px;
      .removeButton {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
      }
    }
  }
}
.paymentSplitError {
  color: #ED5565;
  font-size: 12px;
  font-weight: lighter;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

