.card {
  background: #f6f8fa;
  border-radius: 2px;
  padding: 18px 8px;
  cursor: pointer;
  margin-bottom: 8px;
  animation: ani 1s forwards;
   & > * {
     cursor: pointer;
   }
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 5px;
    &Title {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #227d98;
    }
    .wiLink {
      &:hover {
        text-decoration: underline;
      }
    }
    .cloneButton {
      margin-right: 20px;
    }
  }
  &Info {
    width: 85%;
    margin-top: 9px;
    display: flex;
    justify-content: space-between;
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #808080;
    margin-bottom: 10px;
  }
  &MailInfo {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #808080;
  }
}

.addDocument {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;
  margin-left: 16px;
  color: #227d98;
}

.documentLoader {
  margin-top: 15px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.documents {
  margin-top: 18px;
}

.grayBackground {
  opacity: 0.5;
}

.errorWi {
  border-color: #ef4444;
  border-width: 2px;
  border-style: solid;
}

@keyframes ani {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
