.emailWrapper {
  padding: 8px;
  background: #f8fafc;
  margin-bottom: 10px;
  .filesList {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    .downloadIcon {
      cursor: pointer;
      color:#227D98;
      margin-top: -6px;
      &:active {
        transform: scale(0.8) !important;
      }
    }
  }
  .emailHeader {
    display: flex;
    justify-content: space-between;

    &Right {
      text-align: right;
      .emailDate {
        font-family: 'Lato', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
      }
      .emailSubject {
        font-family: 'Lato', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #606060;

      }
    }
    &Left {
    }
    .emailFrom {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #0e0e2c;
      margin-bottom: 2px;
      display: flex;
      gap: 12px;
    }
    .emailTo, .emailCC {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #606060;
      margin-bottom: 2px;
    }
    .emailCC {
      margin-bottom: 10px;
    }
  }
  .emailBody {
    white-space: break-spaces;
  }
}
