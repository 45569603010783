.alert {
  label {
    color: #ED5565 !important;
    font-family: 'LatoRegular';
    font-size: 14px !important;
  }
}
.alertWrapper {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.alertsWrapper {
  max-height: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
}
