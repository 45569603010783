.d-flex{
    display: flex !important;
}
.d-flex-center{
  display: flex;
  align-items: center;
}
.d-flex-start{
  display: flex;
  align-items: flex-start;
}
.d-flex-sb {
  display: flex;
  justify-content: space-between;
}
.d-flex-center-sb{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d-flex-center-c{
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-start-c {
  display: flex;
  align-items: center;
  justify-content: start;
}

.d-flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.handcursor {
  cursor: pointer;
}

.relative {
  position: relative;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-modal-content {
  border-radius: 6px;
}

.__react_modal_image__medium_img {
  max-height: 70vh !important;
}

.ant-modal-wrap {
  pointer-events: none;
}

.ui.pagination.menu a.item.active {
  background-color: #2474BB !important;
}
.ant-input-number {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.ant-input-number-group-addon {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
