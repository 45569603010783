@import 'variables';

$sidebar-bg: #161619;
$alt-sidebar-bg: #2474BB;
$logo-bg: rgb(31, 31, 34);
$topBarHeight: 80px;
$itemHeight: 60px;

.aria-sidebar {
  border: 0;
  color: rgb(128, 128, 128) !important;
  overflow-y: visible !important;
  padding-bottom: 0 !important;
  position: relative;
  &.thin {
    width: $sidebarWidthThin !important;
  }
  &.very.thin {
    width: $sidebarWidthVeryThin !important;
  }
}

.img-logo {
  cursor: pointer;
  &.thin{
    max-width: 150px;
  }
  &.very.thin{
    max-width: 35px;
  }
}

.item-sb {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  height: $itemHeight;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  //margin: 8px 0px;
  width: auto;
  padding: .92857143em 1.14285714em .92857143em 40px;
  color: #B9B9B9;
  &:hover{
    color: #c7e2e5 !important;
  }
  &.selected{
    //padding: 0px 8px;
    background-color: #72CDF4;
    //margin: 8px;
    //border-radius: 8px !important;
    color: #FFFFFF !important;
    padding-left: 40px;
    &:hover {
      color: $side-BG
    }
    i {
      color: #FFFFFF;
    }
  }
  i.icon {
    min-width: 1em;
    padding-right: 0;
    margin: 0;
    height: auto;
  }
  p {
    padding-left: 1rem;
    text-align: left;
  }
  &.logo {
    justify-content: center;
    background-color: $alt-sidebar-bg;
    margin-top: 0;
    height: $topBarHeight;
    padding: 0;
  }
  .sidebar-icon{
    font-size: 1.2em !important;
    margin: 0 !important;
  }
}

.item-sb.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ui.sidebar.menu.vertical.very.thin{
  overflow: visible !important;
  width: $sidebarWidthVeryThin;

  .item-sb{
    min-width: 0 !important;

    display: flex;
    justify-content: center;
    padding: 0 !important;

    &.selected{
      padding: 0 !important;
    }

    i.icon{
      padding-right: 0;
      font-size: 20px !important;
    }

    p{
      display: none;
    }
  }
}

.collapse-menu-button{
  position: absolute;
  right: -11px;
  top: 19px;
  width: 22px;
  height: 22px;
  background-color: #ebeef3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: #909090;
  cursor: pointer;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  z-index: 9999;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  .icon {
    font-size: 1em;
    line-height: 1em;
  }
  &:hover{
    opacity: 1;
  }
  &.thin {
    padding-left: 2px;
  }
  &.very.thin {
    padding-left: 4px;
  }
}

.current-user-section{
  position: fixed !important;
  bottom: 0;
  width: 100%;
  height: 56px;
  //background: $logo-bg;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  justify-content: space-between;
  &.collapsed {
    .avatar-container {
      flex-grow: 1;
    }
    .collapsed-user-menu{
      transform: translate(48px, 12px);
    }
  }
}

.username{
  padding-left: 10px;
}
