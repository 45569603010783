.wrapper {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;
  margin-left: 16px;
  color: #227D98;
  display: inline-block;
  padding: 0 10px;
}

.active {
  background-color: #ccdfe6;
  //color: red;
}
