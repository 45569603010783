.controlWrapper {
  z-index: 9999;
  right: 8px;
  bottom: 8px;
  position: absolute;
  display: flex;
  gap: 8px;
  .saveButton {
    width: 10px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
  .saveIcon {
    font-size: 25px;
  }
}
.stampButtons {
  bottom: -30px;
  position: absolute;
}
.toolbarFilterButtons {
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  .filterButton {
    border-radius: 4px;
    background-color: #2e86df !important;
  }
  .cancelButton {
    background-color: red !important;
  }
}
.toolbarSliderWrapper {
  padding: 0 5px;
  width: 350px;
  display: flex;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.stamp {
  border: 2px solid steelblue;
  position: absolute;
  left: 0;
  top: 0;
  cursor: grab;
  box-sizing: content-box;
  &:active {
    cursor: move;
  }
}

.canvasWrapper {
  position: relative;
}
.stampItem {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  &:hover {
    cursor: pointer;
    background-color: #72CDF4;
  }
}

.selectedStamp {
  background-color: #72CDF4;
}

.stampsWrapper {
  display: flex;
  gap: 8px;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.resizeHandler {
  width: 20px;
  height: 20px;
  cursor: nwse-resize;
  position: absolute;
}
