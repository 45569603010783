.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px
}
.tenantInfoItem {
  display: flex;
  gap: 10px;
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
