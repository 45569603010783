.fileItem {
  height: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #eef5f7;
  border-radius: 2px;
  padding: 4px 7px;
  .fileIcon {
    &>svg {
      width: 16px;
      height: 22px;
      &>path:last-child {
        fill: #227D98;
      }
    }
  }
  .fileName {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #0E0E2C;
    user-select: none;
  }
  .fileRemove {
  }
}
