.eventsDocumentWrapper {
  background-color: #EBEEF3;
  padding: 10px 16px;
  max-width: 328px;
  height: calc(100vh - 74px);
  overflow: auto;
  // border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 11px;
}
.validateButton {
  background-color: #227D98 !important;
  color: #fff !important;
  width: 100%;
  font-family: 'LatoRegular' !important;
  margin-bottom: 15px !important;
}
