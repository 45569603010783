.wrapper {
  width: 100%;
  padding: 10px;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  .header {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chartTitle {
      margin: 0;
      color: #123B60;
      font-size: 14px;
      font-family: Lato, serif;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  .filters {
    text-align: right;
    padding: 10px 0px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

  }
}
.buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}
.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
