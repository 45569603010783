.line-action-button{
  cursor: pointer;
  height: 32px;
  width: 32px;
  display: flex;
  //border: 3px solid #35984c40;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: inherit;
  border: none;
  &:disabled {
    cursor: default;
    opacity: 0.45;
  }
  &.accept{
    border-color:  #35984c40;
    margin-left: 16px;
  }
  &.decline{
    border-color:  #bd5b5b45;
    margin-left: 4px;
  }
  .action-icon{
    margin-bottom: 5px;
    margin-left: 4px;
  }
  &.transparent {
    background-color: transparent;
    margin-left: 0;
  }
}

.section-button.ui.button.basic {
  color: inherit !important;
  border: 0 !important;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset !important;
  > i.icon {
    margin-top: -2px !important;
  }
  &:hover,
  &:active,
  &:focus {
    color: initial !important;
  }
}

.section-name {
  min-width: 200px;
  margin-bottom: 0;
  color: black;
  align-self: flex-start;
}

.section-name-edit {
  display: flex;
  min-width: 50%;
  align-items: center; 
}

.no-widgets{
  text-align: center;
  padding: 18px;
}
.widget-form {
  .inline-field {
    flex-flow: row nowrap;
    label {
      min-width: 120px !important;
    }
    &.error {
      align-items: flex-start;
      margin-bottom: 0;
    }
  }
}

.full-width {
  width: 100% !important;
}

.section-widgets {
  margin: 8px 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 0 8px;
}

.empty-dashboard {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  color: #a1a1a1;
  span {
    padding-top: 16px;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  cursor: pointer;
}

.section-buttons-container {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  gap: 2px;
}

.dashboard-container {
  padding: 14px 0;
}

.date-range-container {
  min-width: 250px;
  text-align: center;
  cursor: default;
  span {
    color: black;
    margin-bottom: 4px;
    display: inline-block;
  }
}

.loader-container {
  padding: 14px 0;
}